const GlobalVar = Object.freeze({
  BASE_DOMAIN:
    !process.env.NODE_ENV || process.env.NODE_ENV === "development"
      ? "http://localhost/meds/brokers/synergyfxtrades.com/apexfx/public"
      : "https://venusfxtrading.com",

  // SUPPORT_EMAIL: "payment@mytradeoptions.com",
  // BTC_DEPOSIT_ADDRESS: "1BUz66epuSrGtKREPDeEdTeEq7CJoeBHn7", //Andrew
  // BTC_DEPOSIT_ADDRESS: "bc1qnsj3dmq84epk8u3ctplskx8770nm4edkup8x85", // Oruese
  // BTC_DEPOSIT_ADDRESS: "bc1q4386w53fhsnsv52d4urat3usc5u8z6fxpt8eum", // Venus mine
  // BTC_DEPOSIT_ADDRESS: "38VEfGVmoKseR6Hg5jNitRh2rTiD2dPkqX", // Venus
  // ... more of your variables
});

export default GlobalVar;
